import md5 from 'js-md5'
export var SearchList = [
  { labe: '入库单号', code: 'rule_id', type: 'input' },
  { labe: '供应商', code: 'update_userid', type: 'input', placeholder: '编码/名称' },
  {
    labe: '状态',
    code: 'tag',
    type: 'select',
    option: [
      { label: '已入库', value: '已入库' },
      { label: '已撤销', value: '已撤销' }
    ]
  },
  {
    labe: '入库时间',
    code: 'rkTime',
    type: 'daterange'
  },
  {
    labe: '操作类型',
    code: 'proof_type',
    type: 'select',
    option: [
      { label: '收货入库', value: '收货入库' },
      { label: '盘盈入库', value: '盘盈入库' }
    ]
  },
  {
    labe: '入库单类型',
    code: 'proof_in_type',
    type: 'select',
    option: [
      { label: '外协订单入库', value: '1' },
      { label: '供应链订单入库', value: '2' },
      { label: '普通物料入库', value: '3' }
    ]
  }
]
export var SearchData = {
  rule_id: '',
  update_userid: '',
  rkTime: '',
  tag: '',
  proof_type: ''
}
export var tableField = [
  { label: '入库单号', code: 'proofNumber', type: 'input', width: '70%' },
  { label: '供应商编码', code: 'ruleSupplierNumber', type: 'input', width: '70%' },
  { label: '供应商名称', code: 'ruleSupplier', type: 'input', width: ' 80%' },
  { label: '入库时间', code: 'creationDate', type: 'input', width: '80%' },
  {
    label: '入库单类型',
    code: 'ruleOrdersType',
    type: 'function',
    width: '85%',
    handle: (index, data) => {
      return data.ruleOrdersType === '1' ? '外协订单入库' : data.ruleOrdersType === '2' ? '供应链订单入库' : data.ruleOrdersType === '3' ? '普通物料入库' : ''
    }
  },
  { label: '操作类型', code: 'ruleType', type: 'input', width: '60%' },
  { label: '入库操作人', code: 'createUsername', type: 'input', width: '70%' },
  { label: '备注', code: 'ruleComment', type: 'input', width: '65%' },
  // { label: '状态', code: 'proofType', type: 'input', width: '50%' },
  {
    label: '状态',
    code: 'proofType',
    type: 'function',
    width: '55%',
    handle: (index, data) => {
      const color = md5(data.proofType + '').substring(0, 6)
      const label = data.proofType === '已入库' ? '已入库' : data.proofType === '已撤销' ? '已撤销'
        : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '65%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      },
      {
        actionLabel: '撤销',
        operationButton: 'offer',
        stateField: 'proofType',
        stateSymbol: '=',
        stateValue: '已入库'
      }
    ]
  }
]

export var tableData = []

export var bidSupplierList = [
  {
    bidId: '',
    code: '',
    createDate: '',
    createUserId: '',
    id: '',
    isAccept: 0,
    modifyDate: '',
    name: '',
    paymentDays: '',
    serialNumber: 0
  }
]
